<p-table
  *ngIf="columns?.length > 0"
  class="table"
  [class.has-header]="!noHeader"
  dataKey="id"
  [style.--table-row-height]="rowHeight + 'px'"
  [style.--card-height]="(cardsCollapsed ? 56 : cardHeight) + 'px'"
  [filterDelay]="0"
  [globalFilterFields]="enableTableSearch? globalFilterFields: null"
  [scrollHeight]="'flex'"
  [autoLayout]="false"
  [columns]="columns"
  [selectionMode]="selectionMode"
  [rows]="rowsNumber"
  [value]="tableData"
  [selection]="selection"
  [reorderableColumns]="reorderableColumns"
  [resizableColumns]="resizableColumns && !minView"
  [columnResizeMode]="columnResizeMode"
  [scrollable]="scrollable || resizableColumns"
  [virtualScroll]="virtualScroll"
  [virtualScrollOptions]="virtualScrollOptions"
  [virtualScrollItemSize]="minView ? cardHeight : rowHeight"
  [first]="first"
  [rowHover]="true"
  [contextMenu]="cm"
  [rowTrackBy]="trackByFunction"
  [expandedRowKeys]="expandedRowKeys"
  [rowExpandMode]="rowExpandMode"
  (onRowExpand)="updateNumberOfRows({event: $event, expanded: true}); rowExpanded.emit($event)"
  (onRowCollapse)="updateNumberOfRows({event: $event, expanded: false}); rowCollapsed.emit($event)"
  (firstChange)="onFirstChanged($event)"
  (onColReorder)="onColReorder($event)"
  (onSort)="onSortChanged($event)"
  (onRowSelect)="onRowSelected($event)"
  (onRowUnselect)="onRowDeselected($event)"
  (onContextMenuSelect)="openContext($event)"
  (onColResize)="colResize($event)"
>
  <ng-template pTemplate="emptymessage">
    <tr class="no-data-row" [style.height.px]="rowHeight" [style.line-height.px]="rowHeight">
      <div [class.simple]="simple" class="no-data-wrapper" [style.top.px]="this.scrollable && noDataTop">
        <span>{{noDataMessage}}</span>
      </div>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-row>
    <ng-container *ngTemplateOutlet="rowExpansionTemplate; context: {$implicit: row, lastFrame: lastRowExpanded}">
    </ng-container>
  </ng-template>
  <ng-template pTemplate="header" let-columns *ngIf="!noHeader">
    <tr class="header ui-state-default" *ngIf="!minView; else cardHeader">
      <th *ngFor="let col of columns; trackBy: trackByColFn"
          [attr.data-col-id]="col.id"
          [class.disable-drag]="!reorderableColumns || col?.disableDrag"
          [class.resize-disabled]="!resizableColumns"
          [class.resize-enabled]="resizableColumns"
          [class.selected-col-header]="col?.disablePointerEvents"
          [frozen]="col.frozen"
          [pReorderableColumnDisabled]="!reorderableColumns || col?.disableDrag"
          [pResizableColumnDisabled]="col?.disableDrag"
          [style.max-width]="col.style?.maxWidth ?? !resizableColumns && columnResizeMode === 'fit' && col.style?.width"
          [style.min-width]="col.style?.minWidth"
          [style.width]="col.style?.width"
          pFrozenColumn
          pReorderableColumn
          smResizableColumn
      >
        <ng-container [ngSwitch]="col?.headerType">
          <ng-container *ngSwitchCase="colHeaderTypeEnum.sortFilter">
            <ng-container *ngTemplateOutlet="sortFilterTemplate; context: {$implicit: col}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="colHeaderTypeEnum.checkBox">
            <ng-container *ngTemplateOutlet="checkboxTemplate; context: {$implicit: col}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="col-header" [class.text-center]="col.textCenter">{{col.header}}</div>
          </ng-container>
        </ng-container>
      </th>
    </tr>
    <ng-template #cardHeader>
      <tr class="header card-header">
        <th>
          <div class="table-header" style="flex-basis:100%">
            <ng-container *ngTemplateOutlet="checkboxTemplate; context: {$implicit: columns?.[0]}"></ng-container>
            <div class="table-header-title ellipsis" data-id="tableHeader">{{minimizedTableHeader}}</div>
            <div class="collapse-button al-icon al-ico-compact-view sm-md" [class.active]="cardsCollapsed" (click)="cardsCollapsedToggle.emit()"></div>
            <div class="table-actions">
              <div class="recent-title">
                <sm-menu header="SORTED BY " class="menu" panelClasses="light-theme" data-id="sortedByFilter">
                  <div *ngFor="let col of sortableCols; trackBy: trackByColFn">
                    <sm-menu-item [itemLabel]="col.header"
                                  [sortOrder]="getOrder(col.id)"
                                  (itemClicked)="sortItemClick($event, col.id)"></sm-menu-item>
                  </div>
                </sm-menu>
              </div>
              <ng-container *ngTemplateOutlet="cardHeaderTemplate"></ng-container>
            </div>
          </div>
        </th>
      </tr>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex" let-expanded="expanded">
    <tr
      [pRowToggler]="expandableRows? rowData : null"
      [pRowTogglerDisabled]="!expandableRows || !expandRowOnClick"
      [pSelectableRow]="rowData"
      [pContextMenuRow]="rowData"
      [pContextMenuRowDisabled] = !rowRightClick.observed
      [style.height.px]="minView ? (cardsCollapsed ? 56 : this.cardHeight) : this.rowHeight"
      [class.even]="(index + 1) % 2 === 0"
      [class.checked]="isRowSelected(rowData)"
      [class.simple]="simple"
      [class.contexted]="activeContextRow?.id ===rowData?.id && contextMenuOpen"
      [class.selected]="isSelected(rowData)"
      [class.cards-table]="minView"
      [class.pointer]="!!selectionMode"
      (click)="!minView && checkClick({e: $event, data: rowData})"
      (dblclick)="!minView && checkClick({e: $event, data: rowData})"
    >
      <ng-container *ngIf="!minView; else cardBody">
        <td
          *ngFor="let col of columns; trackBy: trackByColFn; index as i"
          pFrozenColumn
          [frozen]="col.frozen"
          [class.ui-resizable-column]="resizableColumns"
          [class.cursor-default]="selectionMode===null"
          [class.simple]="simple"
          [style.min-width]="col.style?.minWidth"
          [style.max-width]="col.style?.maxWidth ?? !resizableColumns && columnResizeMode === 'fit' && col.style?.width"
          [style.width]="col.style?.width"
        >
          <ng-container *ngIf="bodyTemplate; else noBodyTemplate">
            <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: col, rowData: rowData, rowIndex: index, expanded}">
            </ng-container>
          </ng-container>
          <ng-template #noBodyTemplate>
            {{getBodyData(rowData, col)}}
          </ng-template>
        </td>
      </ng-container>
     <ng-template #cardBody>
        <td class="pointer p-0" role="button">
          <div class="card-wrapper">
          <div class="collapse-animation" [style.max-height.px]="cardsCollapsed? 54 : cardHeight">
            <ng-container
              *ngTemplateOutlet="cardTemplate;  context: {rowData: rowData, rowNumber: index, selected: isSelected(rowData)}">
            </ng-container>
          </div>
          </div>
        </td>
      </ng-template>
      <div class="context-button-wrapper">
        <div
          tabindex="10"
          class="context-button"
          *ngIf="rowRightClick.observed && !minView"
          (click)="$event.stopPropagation(); openContext({originalEvent: $event, data: rowData, single: true})"
          (keyup)="$event.stopPropagation(); openContext({originalEvent: $event, data: rowData, single: true})"
        ><i data-id="3Dot Menu Button" class="al-icon al-ico-dots-v-menu" [class.sm-md]="rowHeight < 42" ></i>
        </div>
      </div>
    </tr>
    <tr *ngIf="index === rowsNumber - 1 && lazyLoading && !noMoreData && !lastRowExpanded" class="table-load-more">
      <div (smScrollEnd)="autoLoadMore && loadMore()" class="load-more-wrapper">
        <button #loadButton class="btn btn-cml-primary" data-id="LoadMore" (click)="loadMore()">
          <i *ngIf="loading && !hasExperimentUpdate" class="load-spinner"></i>
          {{hasExperimentUpdate ? 'REFRESH LIST': loading ? 'LOADING...' : 'LOAD MORE' }}
        </button>
      </div>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer" let-columns>
    <ng-container *ngTemplateOutlet="footerTemplate">
    </ng-container>
  </ng-template>
</p-table>
<p-contextMenu #cm [model]="menuItems" class="d-none"></p-contextMenu>
