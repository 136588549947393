<mat-drawer-container autosize class="light-theme">
  <mat-drawer #drawer [opened]="!minimized" [mode]="minimized ? 'over' : 'side'" [disableClose]="!minimized">
    <div class="list-container">
      <sm-graph-settings-bar
        class="drawer-settings-bar"
        *ngIf="!minimized"
        [verticalLayout]="true"
        [smoothWeight]="settings.smoothWeight"
        [smoothType]="settings.smoothType"
        [xAxisType]="settings.xAxisType"
        [groupBy]="settings.groupBy"
        [groupByOptions]="groupByOptions"
        (changeWeight)="changeSmoothness($event)"
        (toggleSettings)="toggleSettingsBar()"
        (changeXAxisType)="changeXAxisType($event)"
        (changeGroupBy)="changeGroupBy($event)"
        (changeSmoothType)="changeSmoothType($event)"
      ></sm-graph-settings-bar>
      <sm-selectable-grouped-filter-list
        *ngIf="!minimized"
        [list]="graphList"
        [checkedList]="settings.selectedMetricsScalar"
        [searchTerm]="searchTerm$ | async"
        (itemSelect)="metricSelected($event)"
        (hiddenChanged)="selectedListChanged($event)"
        (searchTermChanged)="searchTermChanged($event)"
      >
      </sm-selectable-grouped-filter-list>
    </div>
  </mat-drawer>
  <mat-drawer-content class="overflow-hidden">
    <sm-graph-settings-bar
      class="ribbon-setting-bar"
      [class.showSettings]="showSettingsBar && minimized"
      [smoothWeight]="settings.smoothWeight"
      [smoothType]="settings.smoothType"
      [xAxisType]="settings.xAxisType"
      [groupBy]="settings.groupBy"
      [groupByOptions]="groupByOptions"
      [splitSize]="splitSize$ | async"
      (changeWeight)="changeSmoothness($event)"
      (toggleSettings)="toggleSettingsBar()"
      (changeXAxisType)="changeXAxisType($event)"
      (changeGroupBy)="changeGroupBy($event)"
      (changeSmoothType)="changeSmoothType($event)"
    ></sm-graph-settings-bar>
    <sm-experiment-graphs
      [class.with-settings]="showSettingsBar && minimized"
      [metrics]="graphs"
      [hiddenList]="settings.selectedMetricsScalar"
      [isGroupGraphs]="false"
      [isCompare]="true"
      [smoothWeight]="settings.smoothWeight"
      [smoothType]="settings.smoothType"
      [xAxisType]="settings.xAxisType"
      [groupBy]="settings.groupBy"
      [multipleSingleValueData]="singleValues"
      [hoverMode]="hoverMode$ | async"
      [exportForReport]="!modelsFeature"
      [minimized]="minimized"
      [breakPoint]="900"
      [splitSize]="splitSize$ | async"
      (resetGraphs)="resetMetrics()"
      (hoverModeChanged)="hoverModeChanged($event)"
      (createEmbedCode)="createEmbedCode($event)"
    >
    </sm-experiment-graphs>
  </mat-drawer-content>
</mat-drawer-container>
